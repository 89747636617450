import React, { useState, useEffect } from "react";
import { Container, Row } from "react-bootstrap";
import Particle from "../Particle";
import MaterialTable from "material-table";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import {
  Search,
  ChevronLeft,
  ChevronRight,
  Clear,
  ArrowUpward,
} from "@material-ui/icons";

export default function Balances() {
  const [balances, setBalances] = useState([]);
  const [loading, setLoading] = useState(true); // Initialize loading state
  const isBalancesEmpty = balances.length === 0;

  const theme = createTheme({
    palette: {
      mode: "dark",
    },
  });

  useEffect(() => {
    if (isBalancesEmpty) {
      fetch("/api/balances2")
        .then((res) => {
          if (res.ok) {
            return res.json();
          }
          throw new Error("Network response was not ok.");
        })
        .then((jsonRes) => {
          setBalances(jsonRes.balanceData);
          setLoading(false); // Update loading state once data is fetched
          console.log(balances);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          setLoading(false); // Update loading state on error as well
        });
    }
  }, [isBalancesEmpty]);

  const columns = [
    {
      title: "Balance",
      field: "balance",
      render: (rowData) => Math.round(rowData.balance).toLocaleString(),
      width: "20%",
    },
    { title: "User", field: "user", width: "80%" },
  ];

  return (
    <Container fluid className="about-section">
      <Particle />
      <Container>
        <Row style={{ justifyContent: "center", padding: "0px" }}>
          <h1 style={{ fontSize: "2.1em", paddingBottom: "20px" }}>
            <span className="main-name">Moon</span> Holders
          </h1>
          <p style={{ color: "whitesmoke" }}>
            Join the 217,000+ accounts that own MOON
          </p>
          <ThemeProvider theme={theme}>
            <MaterialTable
              title="Top 100k Users"
              columns={columns}
              data={balances}
              isLoading={loading}
              options={{
                pageSize: 10,
                pagination: false,
                search: true,
                sorting: true,
                showTitle: true,
                showFirstLastPageButtons: false,
              }}
              icons={{
                NextPage: ChevronRight,
                PreviousPage: ChevronLeft,
                Search: Search,
                Clear: Clear,
                ResetSearch: Clear,
                SortArrow: ArrowUpward,
                DetailPanel: ChevronRight,
              }}
              detailPanel={[
                {
                  tooltip: "Show Address",
                  render: (rowData) => {
                    // If the address is a string representing an array (e.g., '[addr1, addr2]')
                    let addresses = [];

                    if (typeof rowData.address === "string") {
                      // Remove the square brackets and split by commas
                      addresses = rowData.address
                        .replace(/[^\w\s,]/g, "") // Remove any characters that are not addresses or commas (like [])
                        .split(",") // Split into an array based on commas
                        .map((addr) => addr.trim()); // Remove any extra spaces
                    } else {
                      addresses = [rowData.address]; // If it's not a string, assume it's a single address
                    }

                    // Render each address as a link
                    return (
                      <div>
                        {addresses.length > 0 ? (
                          addresses.map((address, index) => (
                            <div key={index}>
                              <a
                                href={`https://nova.arbiscan.io/address/${address}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{
                                  color: "#c770f0",
                                  display: "block",
                                  marginBottom: "5px",
                                  textDecoration: "none",
                                }}
                              >
                                {address}
                              </a>
                            </div>
                          ))
                        ) : (
                          <div>No addresses available</div>
                        )}
                      </div>
                    );
                  },
                },
              ]}
            />
          </ThemeProvider>
        </Row>
      </Container>
    </Container>
  );
}
